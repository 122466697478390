/**
 * Do not edit directly
 * These files are generated from design tokens defined in Figma using Token Studio
 */

:root {
  /** Referenced source tokens */ 
  --fds-font-size-f0: clamp(1.00rem, calc(0.19vw + 0.96rem), 1.13rem);

  /** Tokens */ 
  --fds-semantic-background-default: #ffffff;
  --fds-semantic-background-subtle: #f4f5f6;
  --fds-semantic-surface-neutral-default: #ffffff;
  --fds-semantic-surface-neutral-selected: #e6eff8;
  --fds-semantic-surface-neutral-subtle: #f4f5f6;
  --fds-semantic-surface-neutral-subtle-hover: #e9eaec;
  --fds-semantic-surface-neutral-dark: #68707c;
  --fds-semantic-surface-neutral-dark-hover: #4b5563;
  --fds-semantic-surface-neutral-inverted: #1e2b3c;
  --fds-semantic-surface-action-subtle: #e6eff8;
  --fds-semantic-surface-action-subtle-hover: #b3d0ea;
  --fds-semantic-surface-action-default: #0062ba;
  --fds-semantic-surface-action-hover: #004e95;
  --fds-semantic-surface-action-active: #00315d;
  --fds-semantic-surface-action-no_fill: #ffffff;
  --fds-semantic-surface-action-no_fill-hover: #e6eff8;
  --fds-semantic-surface-action-no_fill-active: #b3d0ea;
  --fds-semantic-surface-action-checked: #0062ba;
  --fds-semantic-surface-action-primary-subtle: #e6eff8;
  --fds-semantic-surface-action-primary-subtle-hover: #b3d0ea;
  --fds-semantic-surface-action-primary-default: #0062ba;
  --fds-semantic-surface-action-primary-hover: #004e95;
  --fds-semantic-surface-action-primary-active: #00315d;
  --fds-semantic-surface-action-primary-no_fill: #ffffff;
  --fds-semantic-surface-action-primary-no_fill-hover: #e6eff8;
  --fds-semantic-surface-action-primary-no_fill-active: #b3d0ea;
  --fds-semantic-surface-action-secondary-subtle: #f4f5f6;
  --fds-semantic-surface-action-secondary-subtle-hover: #e9eaec;
  --fds-semantic-surface-action-secondary-default: #00315d;
  --fds-semantic-surface-action-secondary-hover: rgba(0, 49, 93, 0.9);
  --fds-semantic-surface-action-secondary-active: rgba(0, 49, 93, 0.8);
  --fds-semantic-surface-action-secondary-no_fill: #ffffff;
  --fds-semantic-surface-action-secondary-no_fill-hover: rgba(0, 49, 93, 0.1);
  --fds-semantic-surface-action-secondary-no_fill-active: rgba(0, 49, 93, 0.2);
  --fds-semantic-surface-success-subtle: #d1f4e1;
  --fds-semantic-surface-success-subtle-hover: #8be4b5;
  --fds-semantic-surface-success-default: #118849;
  --fds-semantic-surface-success-hover: #0c6536;
  --fds-semantic-surface-success-active: #084826;
  --fds-semantic-surface-success-no_fill: #ffffff;
  --fds-semantic-surface-success-no_fill-hover: #d1f4e1;
  --fds-semantic-surface-success-no_fill-active: #8be4b5;
  --fds-semantic-surface-warning-subtle: #fffbe6;
  --fds-semantic-surface-warning-subtle-hover: #fff4b4;
  --fds-semantic-surface-warning-default: #ff8c06;
  --fds-semantic-surface-danger-subtle: #f9d5db;
  --fds-semantic-surface-danger-subtle-hover: #f3abb6;
  --fds-semantic-surface-danger-default: #e02e49;
  --fds-semantic-surface-danger-hover: #b3253a;
  --fds-semantic-surface-danger-active: #861c2c;
  --fds-semantic-surface-danger-no_fill: #ffffff;
  --fds-semantic-surface-danger-no_fill-hover: #f9d5db;
  --fds-semantic-surface-danger-no_fill-active: #f3abb6;
  --fds-semantic-surface-info-subtle: #e6eff8;
  --fds-semantic-surface-info-subtle-hover: #b3d0ea;
  --fds-semantic-surface-on_inverted-default: #ffffff;
  --fds-semantic-surface-on_inverted-hover: #ffffffe6;
  --fds-semantic-surface-on_inverted-active: #ffffffcc;
  --fds-semantic-surface-on_inverted-no_fill: #ffffff00;
  --fds-semantic-surface-on_inverted-no_fill-hover: #ffffff1a;
  --fds-semantic-surface-on_inverted-no_fill-active: #ffffff33;
  --fds-semantic-surface-primary-light: #D1E4FF;
  --fds-semantic-surface-primary-light-hover: #76ADFB;
  --fds-semantic-surface-primary-light-active: #5391E9;
  --fds-semantic-surface-primary-dark: #004785;
  --fds-semantic-surface-secondary-light: #C9E7F4;
  --fds-semantic-surface-secondary-light-hover: #70B3D7;
  --fds-semantic-surface-secondary-light-active: #4E99BD;
  --fds-semantic-surface-secondary-dark: #1F4B63;
  --fds-semantic-surface-tertiary-light: #D7E5E7;
  --fds-semantic-surface-tertiary-light-hover: #B9CACB;
  --fds-semantic-surface-tertiary-light-active: #9FAEAF;
  --fds-semantic-surface-tertiary-dark: #414849;
  --fds-semantic-border-info-default: #004e95;
  --fds-semantic-border-action-subtle: #b3d0ea;
  --fds-semantic-border-action-subtle-hover: #66a1d6;
  --fds-semantic-border-action-default: #0062ba;
  --fds-semantic-border-action-hover: #0062ba;
  --fds-semantic-border-action-active: #00315d;
  --fds-semantic-border-action-dark: #00315d;
  --fds-semantic-border-action-dark-hover: #0062ba;
  --fds-semantic-border-action-primary-subtle: #b3d0ea;
  --fds-semantic-border-action-primary-subtle-hover: #66a1d6;
  --fds-semantic-border-action-primary-default: #0062ba;
  --fds-semantic-border-action-primary-hover: #004e95;
  --fds-semantic-border-action-primary-active: #00315d;
  --fds-semantic-border-action-secondary-subtle: #e9eaec;
  --fds-semantic-border-action-secondary-subtle-hover: #d2d5d8;
  --fds-semantic-border-action-secondary-default: #00315d;
  --fds-semantic-border-action-secondary-hover: #00315d;
  --fds-semantic-border-action-secondary-active: #00315d;
  --fds-semantic-border-neutral-default: #68707c;
  --fds-semantic-border-neutral-subtle: #d2d5d8;
  --fds-semantic-border-neutral-strong: #1e2b3c;
  --fds-semantic-border-neutral-divider: #bcbfc5;
  --fds-semantic-border-success-default: #118849;
  --fds-semantic-border-success-hover: #0c6536;
  --fds-semantic-border-success-active: #084826;
  --fds-semantic-border-warning-default: #cc7005;
  --fds-semantic-border-warning-hover: #995404;
  --fds-semantic-border-warning-active: #663802;
  --fds-semantic-border-danger-default: #e02e49;
  --fds-semantic-border-danger-hover: #b3253a;
  --fds-semantic-border-danger-active: #861c2c;
  --fds-semantic-border-focus-outline: #ffda06;
  --fds-semantic-border-focus-boxshadow: #1e2b3c;
  --fds-semantic-border-on_inverted-default: #ffffff;
  --fds-semantic-border-input-default: #00315d;
  --fds-semantic-border-input-hover: #0062ba;
  --fds-semantic-border-primary-default: #005FAE;
  --fds-semantic-border-primary-hover: #004785;
  --fds-semantic-border-primary-active: #00315E;
  --fds-semantic-border-secondary-default: #2C647E;
  --fds-semantic-border-secondary-hover: #1F4B63;
  --fds-semantic-border-secondary-active: #133349;
  --fds-semantic-border-tertiary-default: #879394;
  --fds-semantic-border-tertiary-hover: #6D7879;
  --fds-semantic-border-tertiary-active: #576061;
  --fds-semantic-text-success-default: #0c6536;
  --fds-semantic-text-success-hover: #084826;
  --fds-semantic-text-success-active: #084826;
  --fds-semantic-text-success-on_success: #ffffff;
  --fds-semantic-text-success-on_success_subtle: #1e2b3c;
  --fds-semantic-text-neutral-default: #1e2b3c;
  --fds-semantic-text-neutral-subtle: #4b5563;
  --fds-semantic-text-neutral-on_inverted: #ffffff;
  --fds-semantic-text-action-default: #0062ba;
  --fds-semantic-text-action-hover: #004e95;
  --fds-semantic-text-action-active: #00315d;
  --fds-semantic-text-action-on_action: #ffffff;
  --fds-semantic-text-action-primary-default: #0062ba;
  --fds-semantic-text-action-primary-hover: #004e95;
  --fds-semantic-text-action-primary-active: #00315d;
  --fds-semantic-text-action-primary-on_action: #ffffff;
  --fds-semantic-text-action-secondary-default: #00315d;
  --fds-semantic-text-action-secondary-hover: #00315d;
  --fds-semantic-text-action-secondary-active: #00315d;
  --fds-semantic-text-action-secondary-on_action: #ffffff;
  --fds-semantic-text-warning-default: #995404;
  --fds-semantic-text-warning-icon_warning: #cc7005;
  --fds-semantic-text-warning-on_warning: #663802;
  --fds-semantic-text-danger-default: #b3253a;
  --fds-semantic-text-danger-hover: #861c2c;
  --fds-semantic-text-danger-active: #5a121d;
  --fds-semantic-text-danger-on_danger: #ffffff;
  --fds-semantic-text-danger-on_danger_subtle: #1e2b3c;
  --fds-semantic-text-visited-default: #7a1265;
  --fds-typography-heading-xlarge: 500 clamp(1.59rem, calc(1.15vw + 1.36rem), 2.34rem)/1.3 'Inter';
  --fds-typography-heading-large: 500 clamp(1.47rem, calc(0.86vw + 1.30rem), 2.03rem)/1.3 'Inter';
  --fds-typography-heading-medium: 500 clamp(1.34rem, calc(0.63vw + 1.22rem), 1.75rem)/1.3 'Inter';
  --fds-typography-heading-small: 500 clamp(1.22rem, calc(0.43vw + 1.13rem), 1.50rem)/1.3 'Inter';
  --fds-typography-heading-xsmall: 500 clamp(1.09rem, calc(0.34vw + 1.03rem), 1.31rem)/1.3 'Inter';
  --fds-typography-heading-xxsmall: 500 clamp(1.00rem, calc(0.19vw + 0.96rem), 1.13rem)/1.3 'Inter';
  --fds-typography-ingress-medium: 400 clamp(1.09rem, calc(0.34vw + 1.03rem), 1.31rem)/1.6 'Inter';
  --fds-typography-paragraph-large: 400 clamp(1.09rem, calc(0.34vw + 1.03rem), 1.31rem)/1.5 'Inter';
  --fds-typography-paragraph-medium: 400 clamp(1.00rem, calc(0.19vw + 0.96rem), 1.13rem)/1.5 'Inter';
  --fds-typography-paragraph-small: 400 clamp(0.91rem, calc(0.10vw + 0.89rem), 0.97rem)/1.5 'Inter';
  --fds-typography-paragraph-xsmall: 400 clamp(0.81rem, calc(0.05vw + 0.80rem), 0.84rem)/1.5 'Inter';
  --fds-typography-paragraph-short-large: 400 clamp(1.09rem, calc(0.34vw + 1.03rem), 1.31rem)/1.3 'Inter';
  --fds-typography-paragraph-short-medium: 400 clamp(1.00rem, calc(0.19vw + 0.96rem), 1.13rem)/1.3 'Inter';
  --fds-typography-paragraph-short-small: 400 clamp(0.91rem, calc(0.10vw + 0.89rem), 0.97rem)/1.3 'Inter';
  --fds-typography-paragraph-short-xsmall: 400 clamp(0.81rem, calc(0.05vw + 0.80rem), 0.84rem)/1.3 'Inter';
  --fds-typography-label-large: 500 clamp(1.09rem, calc(0.34vw + 1.03rem), 1.31rem)/1.3 'Inter';
  --fds-typography-label-medium: 500 clamp(1.00rem, calc(0.19vw + 0.96rem), 1.13rem)/1.3 'Inter';
  --fds-typography-label-small: 500 clamp(0.91rem, calc(0.10vw + 0.89rem), 0.97rem)/1.3 'Inter';
  --fds-typography-label-xsmall: 500 clamp(0.81rem, calc(0.05vw + 0.80rem), 0.84rem)/1.3 'Inter';
  --fds-typography-error_message-large: 400 clamp(1.09rem, calc(0.34vw + 1.03rem), 1.31rem)/1.3 'Inter';
  --fds-typography-error_message-medium: 400 clamp(1.00rem, calc(0.19vw + 0.96rem), 1.13rem)/1.3 'Inter';
  --fds-typography-error_message-small: 400 clamp(0.91rem, calc(0.10vw + 0.89rem), 0.97rem)/1.3 'Inter';
  --fds-typography-error_message-xsmall: 400 clamp(0.81rem, calc(0.05vw + 0.80rem), 0.84rem)/1.3 'Inter';
  --fds-typography-interactive-large: 400 1.5rem/1.3 'Inter';
  --fds-typography-interactive-medium: 400 1.125rem/1.3 'Inter';
  --fds-typography-interactive-small: 400 1rem/1.3 'Inter';
  --fds-opacity-disabled: 30%;
  --fds-border_radius-interactive: 4px;
  --fds-border_radius-small: 2px;
  --fds-border_radius-medium: 4px;
  --fds-border_radius-large: 8px;
  --fds-border_radius-xlarge: 12px;
  --fds-border_radius-full: 9999px;
  --fds-spacing-0: calc(0);
  --fds-spacing-1: calc(var(--fds-base_spacing)/16*var(--fds-font-size-f0));
  --fds-spacing-2: calc(var(--fds-base_spacing)*2/16*var(--fds-font-size-f0));
  --fds-spacing-3: calc(var(--fds-base_spacing)*3/16*var(--fds-font-size-f0));
  --fds-spacing-4: calc(var(--fds-base_spacing)*4/16*var(--fds-font-size-f0));
  --fds-spacing-5: calc(var(--fds-base_spacing)*5/16*var(--fds-font-size-f0));
  --fds-spacing-6: calc(var(--fds-base_spacing)*6/16*var(--fds-font-size-f0));
  --fds-spacing-7: calc(var(--fds-base_spacing)*7/16*var(--fds-font-size-f0));
  --fds-spacing-8: calc((var(--fds-base_spacing)*8/16)*var(--fds-font-size-f0));
  --fds-spacing-10: calc((var(--fds-base_spacing)*10/16)*var(--fds-font-size-f0));
  --fds-spacing-12: calc((var(--fds-base_spacing)*12/16)*var(--fds-font-size-f0));
  --fds-spacing-14: calc((var(--fds-base_spacing)*14/16)*var(--fds-font-size-f0));
  --fds-spacing-18: calc((var(--fds-base_spacing)*18/16)*var(--fds-font-size-f0));
  --fds-spacing-22: calc((var(--fds-base_spacing)*22/16)*var(--fds-font-size-f0));
  --fds-spacing-26: calc((var(--fds-base_spacing)*26/16)*var(--fds-font-size-f0));
  --fds-spacing-30: calc((var(--fds-base_spacing)*30/16)*var(--fds-font-size-f0));
  --fds-base_spacing: 4;
  --fds-sizing-1: calc(var(--fds-base_sizing)/16*var(--fds-font-size-f0));
  --fds-sizing-2: calc(var(--fds-base_sizing)*2/16*var(--fds-font-size-f0));
  --fds-sizing-3: calc(var(--fds-base_sizing)*3/16*var(--fds-font-size-f0));
  --fds-sizing-4: calc(var(--fds-base_sizing)*4/16*var(--fds-font-size-f0));
  --fds-sizing-5: calc(var(--fds-base_sizing)*5/16*var(--fds-font-size-f0));
  --fds-sizing-6: calc(var(--fds-base_sizing)*6/16*var(--fds-font-size-f0));
  --fds-sizing-7: calc(var(--fds-base_sizing)*7/16*var(--fds-font-size-f0));
  --fds-sizing-8: calc(var(--fds-base_sizing)*8/16*var(--fds-font-size-f0));
  --fds-sizing-10: calc(var(--fds-base_sizing)*10/16*var(--fds-font-size-f0));
  --fds-sizing-12: calc((var(--fds-base_sizing)*12/16)*var(--fds-font-size-f0));
  --fds-sizing-14: calc((var(--fds-base_sizing)*14/16)*var(--fds-font-size-f0));
  --fds-sizing-18: calc((var(--fds-base_sizing)*18/16)*var(--fds-font-size-f0));
  --fds-sizing-22: calc((var(--fds-base_sizing)*22/16)*var(--fds-font-size-f0));
  --fds-sizing-26: calc((var(--fds-base_sizing)*26/16)*var(--fds-font-size-f0));
  --fds-sizing-30: calc((var(--fds-base_sizing)*30/16)*var(--fds-font-size-f0));
  --fds-base_sizing: 4;
  --fds-border_width-default: 1px;
  --fds-border_width-active: 2px;
  --fds-border_width-tab_focus: 2px;
  --fds-shadow-xsmall: 0 1px 2px 0 #0000001f;
  --fds-shadow-small: 0 1px 2px -1px #0000001a, 0 1px 4px 0 #0000001a;
  --fds-shadow-medium: 0 2px 4px -2px #0000001a, 0 4px 5px #0000001a;
  --fds-shadow-large: 0 4px 6px -4px #0000001a, 0 10px 12px #0000001a;
  --fds-shadow-xlarge: 0 8px 10px -6px #0000001a, 0 25px 45px #0000001a;
  --fds-brand-primary-50: #FFFFFF;
  --fds-brand-primary-100: #D1E4FF;
  --fds-brand-primary-200: #9ECAFF;
  --fds-brand-primary-300: #76ADFB;
  --fds-brand-primary-400: #5391E9;
  --fds-brand-primary-500: #2B77CC;
  --fds-brand-primary-600: #005FAE;
  --fds-brand-primary-700: #004785;
  --fds-brand-primary-800: #00315E;
  --fds-brand-primary-900: #001C3A;
  --fds-brand-primary-950: #001127;
  --fds-brand-secondary-50: #FFFFFF;
  --fds-brand-secondary-100: #E5F3F9;
  --fds-brand-secondary-200: #C9E7F4;
  --fds-brand-secondary-300: #70B3D7;
  --fds-brand-secondary-400: #4E99BD;
  --fds-brand-secondary-500: #3B7D9B;
  --fds-brand-secondary-600: #2C647E;
  --fds-brand-secondary-700: #1F4B63;
  --fds-brand-secondary-800: #133349;
  --fds-brand-secondary-900: #071D2C;
  --fds-brand-secondary-950: #03121D;
  --fds-brand-tertiary-50: #EBF2F3;
  --fds-brand-tertiary-100: #D7E5E7;
  --fds-brand-tertiary-200: #B9CACB;
  --fds-brand-tertiary-300: #9FAEAF;
  --fds-brand-tertiary-400: #879394;
  --fds-brand-tertiary-500: #6D7879;
  --fds-brand-tertiary-600: #576061;
  --fds-brand-tertiary-700: #414849;
  --fds-brand-tertiary-800: #2C3132;
  --fds-brand-tertiary-900: #191C1C;
  --fds-brand-tertiary-950: #0F1112;
  --fds-component-mode-height-small: 36px;
  --fds-component-mode-height-medium: var(--fds-sizing-10);
  --fds-component-mode-height-large: var(--fds-sizing-12);
  --fds-component-mode-spacing-small: var(--fds-spacing-2);
  --fds-component-mode-spacing-medium: var(--fds-spacing-3);
  --fds-component-mode-spacing-large: var(--fds-spacing-4);
  --fds-component-mode-gap-small: var(--fds-spacing-1);
  --fds-component-mode-gap-medium: var(--fds-spacing-2);
  --fds-component-mode-gap-large: var(--fds-spacing-3);
}

/* cyrillic-ext */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/faac4ac11aa3d97b-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Open_Sans_86737b';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/3d9ea938b6afa941-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Open_Sans_Fallback_86737b';src: local("Arial");ascent-override: 101.65%;descent-override: 27.86%;line-gap-override: 0.00%;size-adjust: 105.15%
}.__className_86737b {font-family: '__Open_Sans_86737b', '__Open_Sans_Fallback_86737b', sans-serif;font-style: normal
}

